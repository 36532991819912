import PropTypes from "prop-types"
import React from "react"

import * as styles from "./Layout.module.scss"

const Layout = ({ children }) => {
  return <main className={styles.main}>{children}</main>
}

export default Layout

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

// Layout.defaultProps = {}
